/**
 * Helper class to calculate adjusted gas value that is higher than estimate
 */
export class GasHelper {
  static gasMulptiplier = 1.4 // Increase by 40%

  static gasPay(gasLimit: BigInt | string | number) {
    return Math.ceil(Number(gasLimit) * GasHelper.gasMulptiplier).toString()
  }

  static gasPricePlusPercent(gasPrice: bigint, percent: 10 | 20 | 50 | 100 | 150 | 200) {
    const _percent = percent / 100
    return BigInt(Math.ceil(Number(gasPrice) * (1 + _percent)))
  }
}