import React, {Fragment} from "react";

import {AlertBlock, Button, Input, Loader, Modal} from "../../../components";
import {ButtonSize} from "../../../constants/ui";
import {
  AddressType,
  IMapValueByAddress,
  NetworkCurrencyEnum,
  NetworkType,
  PrivateKeyType
} from "../../ConsolidationTool/types";
import {useModal} from "../../../hooks/useModal";

export interface IDisperseToolView {
  linkForTxScan: string,
  networkCurrency: typeof NetworkCurrencyEnum[NetworkType],
  title: string,
  amountByReceiver: {
    value: IMapValueByAddress<number>,
    total: bigint,
    setAmountByReceiver(account: AddressType, amount: number): void,
    removeReceiver(account: AddressType): void,
    limit: number,
    isLimit: boolean,
    error: string | null,
  },
  syncAmount: {
    isSync: boolean,
    setIsSyncAmount(value: boolean): void
  },
  receiverAndAmountInput: {
    receiver: {
      value: string | null,
      handleChange(address: string | AddressType): void,
      error: string | null
    },
    amount: {
      value: number | null,
      handleChange(amount: number | string): void
    },
    addReceiver(): void,
  },
  senderAccount: {
    address: {
      value: AddressType | null,
      handleChange(address: AddressType): void,
      error: string | null,
    },
    balance: {
      value: number,
      error: string | null
    },
    privateKey: {
      handleChange(privateKey: PrivateKeyType): void,
      error: string | null
    }
  },
  estimate: {
    totalAmountSend: string,
    handleEstimate(): void,
    isDisabled: boolean,
    error: string | null,
    fee: {
      value: number,
      handleSelect(isOptimized: boolean): void,
      options: [
        {
          label: string,
          isOptimized: boolean,
          fee: bigint,
          isSelected: boolean,
        }
      ],
    },
    isLoading: boolean
  },
  send: {
    txHashByAddress: IMapValueByAddress,
    handleSend(callBack: () => void): void,
    total: number,
    isDisabled: boolean,
    isSuccess: true | undefined,
    isProcessing: boolean,
    error: string | null
  },
}

export const DisperseToolView = (props: IDisperseToolView) => {
  const {
    amountByReceiver, networkCurrency,
    senderAccount, title,
    syncAmount,
    estimate, send, linkForTxScan,
    receiverAndAmountInput
  } = props

  const {
    isOpenModal,
    closeModal,
    openModal
  } = useModal()

  return (
    <>
      <div className="justify-center items-center">
        <h1 className="h1-title">Disperse Tool {title}</h1>
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-3">
          <div className="label-primary !mb-0 !flex justify-between items-center gap-5">
            Address

            <div>
            <span className={amountByReceiver.isLimit ? 'text-red-700' : ''}>
            {amountByReceiver.value.size}</span>/{amountByReceiver.limit}
            </div>
          </div>
          <div className="label-primary !mb-0 !flex justify-between gap-5 items-center">
            Amount send {networkCurrency}

            <Button
              className="btn-primary-outline py-1.5 w-10"
              size={ButtonSize.XS}
              onClick={() => syncAmount.setIsSyncAmount(!syncAmount.isSync)}
            >
              {syncAmount.isSync ? <i className="fas fa-lock text-sm"/> :
                <i className="fas fa-lock-open text-sm"/>}
            </Button>
          </div>

          <div className="label-primary !mb-0 !p-0">
            {(send.isSuccess) ? 'Link to scanner' : 'Action'}
          </div>

          {Array.from(amountByReceiver.value).map(([account, amountBaseCurrency], index) => (
            <Fragment key={`Fragment${account}`}>
              <div
                className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 flex items-center">
                <p className="text-ellipsis overflow-hidden">{account}</p>
              </div>
              <div>
                <Input
                  type="number"
                  value={amountBaseCurrency}
                  disabled={syncAmount.isSync && index > 0}
                  onChange={(event) => amountByReceiver.setAmountByReceiver(account, +event.target.value)}
                />
              </div>
              <div>
                {send.isSuccess && send.txHashByAddress.has(account) ?
                  (<a href={`${linkForTxScan}${send.txHashByAddress.get(account)}`} target="_blank"
                      className="text-blue-700">txHash</a>) : ''}
                {!send.isSuccess ?
                  (<Button type="button"
                           onClick={() => amountByReceiver.removeReceiver(account)}
                           className="btn-primary w-12 h-12"
                           size={ButtonSize.XS}
                  >
                    <i className="fas fa-minus text-base"/>
                  </Button>) : ''}
              </div>
            </Fragment>
          ))}

          {
            amountByReceiver.isLimit ? (
              <AlertBlock
                classNameBlock="mt-2.5 !p-5 max-w-[66%] w-full col-span-3"
                bgColorBlock="bg-red-700/40"
                title="Error"
              >
                <p>
                  You have entered more addresses than specified in the limit. The limit is {amountByReceiver.limit}.
                </p>
              </AlertBlock>
            ) : ''
          }

          {
            (send.isSuccess || amountByReceiver.isLimit || amountByReceiver.isLimit) ? '' :
              (<>
                <div>
                  <div className="">
                    <Input
                      placeholder="Enter address"
                      type="text"
                      name="address"
                      id="address"
                      value={receiverAndAmountInput.receiver.value || ''}
                      isInvalid={!!receiverAndAmountInput.receiver.error}
                      feedback={receiverAndAmountInput.receiver.error}
                      onChange={(e) => receiverAndAmountInput.receiver.handleChange(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <div className="">
                    <Input
                      placeholder="Enter amount"
                      type="number"
                      name="amount"
                      id="amount"
                      value={receiverAndAmountInput.amount.value === null ? '' : receiverAndAmountInput.amount.value}
                      onChange={(event) => receiverAndAmountInput.amount.handleChange(event.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <Button type="button"
                          onClick={receiverAndAmountInput.addReceiver}
                          className="btn-primary w-12 h-12"
                          size={ButtonSize.XS}
                          disabled={!receiverAndAmountInput.receiver.value || !receiverAndAmountInput.amount.value}
                  >
                    <i className="fas fa-plus text-base"/>
                  </Button>
                </div>
              </>)
          }

          {
            amountByReceiver.value.size ? (
              <>
                <div
                  className="block w-full px-3.5 py-2 text-right sm:text-sm sm:leading-6">
                  Total Amount Send {networkCurrency}
                </div>
                <div>
                  <Input
                    type="text"
                    value={amountByReceiver.total.toString()}
                    disabled
                  />
                </div>
                <div className="block w-full py-2 shadow-sm sm:text-sm sm:leading-6"/>
              </>
            ) : ''
          }

          {
            amountByReceiver.value.size ? (
              <div>
                <label
                  htmlFor="senderAddress"
                  className="label-primary">
                  Sender address
                </label>
                <div className="mt-2.5">
                  <Input
                    type="text"
                    name="senderAddress"
                    id="senderAddress"
                    value={senderAccount.address.value || ''}
                    onChange={(event) => senderAccount.address.handleChange(event.target.value)}
                    placeholder="Enter Sender Address"
                    isInvalid={!!senderAccount.address.error}
                    feedback={senderAccount.address.error}
                  />
                </div>
              </div>
            ) : ''
          }

          {senderAccount.address.value?.length ? (
            <div>
              <label
                htmlFor="senderBalance"
                className="label-primary">
                Balance {networkCurrency}
              </label>
              <div className="mt-2.5">
                <Input
                  type="text"
                  name="senderBalance"
                  id="senderBalance"
                  disabled
                  value={senderAccount.balance.value || 0}
                  isInvalid={!!senderAccount.balance.error}
                  feedback={senderAccount.balance.error}
                />
              </div>
            </div>
          ) : ''}

          {/*if address sender already entered*/}
          {
            (senderAccount.address.value && !senderAccount.address.error) ? (
              <div className="flex items-end">
                {(send.isSuccess) ? ''
                  : (
                    <div className="flex gap-5">
                      <Button
                        className="btn-primary w-12 h-12"
                        size={ButtonSize.XS}
                        type="button"
                        disabled={estimate.isDisabled}
                        onClick={estimate.handleEstimate}
                      >
                        <i className="fas fa-calculator text-base"/>
                      </Button>

                      {estimate.isLoading ? <Loader/> : null}
                    </div>
                  )}
              </div>
            ) : ''
          }
        </div>

        <div className="grid grid-cols-3 gap-x-8 gap-y-6">
          <div className="mt-5 col-span-2	">
            {/*info about estimate*/}
            {
              Object.values(estimate.fee.options).length ? (
                <>
                  <div className="grid gap-x-8 gap-y-6">
                    <AlertBlock
                      classNameBlock="mt-2.5 !p-5 max-w-[50%] w-full"
                      bgColorBlock="bg-yellow-800/10"
                      title="Attention!"
                    >
                      <p>
                        Total fee dependence on you choice
                      </p>
                    </AlertBlock>

                    <div className="label-primary !mb-0">Select approach for send transaction:</div>

                    <div className="grid grid-cols-2 gap-x-8 gap-y-6">
                      {
                        estimate.fee.options.map(optionItem => (
                          <label className="cursor-pointer" key={optionItem.label}>
                            <input type="radio" className="peer sr-only" checked={optionItem.isSelected} name="pricing"
                                   onChange={() => estimate.fee.handleSelect(optionItem.isOptimized)}/>
                            <div
                              className=" rounded-md bg-white p-5 text-black ring-2 ring-transparent transition-all hover:shadow peer-checked:text-indigo-600 peer-checked:ring-indigo-600 peer-checked:ring-offset-2">
                              <div className="flex flex-col gap-1">
                                <div className="flex items-center justify-between">
                                  <p className="text-sm font-semibold uppercase text-gray-500">{optionItem.label}</p>
                                  <div>
                                    {!optionItem.isSelected && <svg width="24" height="24" viewBox="0 0 24 24">
                                        <path fill="currentColor"
                                              d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"></path>
                                    </svg>}
                                    {optionItem.isSelected && <svg width="24" height="24" viewBox="0 0 24 24">
                                        <path fill="currentColor"
                                              d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"></path>
                                    </svg>}
                                  </div>
                                </div>
                                <div className="flex items-end justify-between">
                                  <p>
                                    Fee <span
                                    className="text-lg font-bold">
                                          {optionItem.fee}
                                        </span> {networkCurrency}
                                  </p>
                                  {/*<p className="text-sm font-bold">~$NaN</p>*/}
                                </div>
                              </div>
                            </div>
                          </label>
                        ))
                      }
                    </div>
                  </div>
                </>
              ) : null
            }

            {
              estimate.error ? (
                <div className={'invalid-feedback feedback'}>
                  {estimate.error}
                </div>
              ) : null
            }
          </div>

          {
            senderAccount.address.value && amountByReceiver.value.size && estimate.fee.value ? (
              <div className="mt-5 grid grid-cols-3 gap-x-8 gap-y-6 col-span-3 items-center">
                <label
                  className="label-primary !mb-0">
                  Total amount will spend (value + fee) {networkCurrency}
                </label>
                <Input
                  type="text"
                  value={send.total}
                  disabled
                  isInvalid={!!senderAccount.balance.error}
                  feedback={senderAccount.balance.error}
                />
                {send.isSuccess ? (
                  <>
                    {/*NOTICE: Only for Disperse approach !!*/}
                    {/*{*/}
                    {/*  dataTxReceipt ? (*/}
                    {/*    <a href={`${linkForTxScan}${dataTxReceipt}`} target="_blank"*/}
                    {/*       className="text-blue-700">txHash</a>*/}
                    {/*  ) : ''*/}
                    {/*}*/}
                  </>
                ) : (
                  <div className="flex gap-5">
                    <Button
                      className="btn-primary w-12 h-12"
                      size={ButtonSize.XS}
                      type="button"
                      onClick={() => {
                        if (send.isDisabled) return
                        openModal()
                      }}
                      disabled={send.isDisabled}
                    >
                      <i className="fas fa-paper-plane text-base font-bold"/>
                    </Button>

                    {send.isProcessing ? <Loader/> : null}
                  </div>

                )
                }
              </div>
            ) : ''
          }
        </div>
      </div>

      <Modal title="Private key" size="lg" show={isOpenModal} handleClose={closeModal}>
        <label className="label-primary">Enter private key</label>
        <Input
          disabled={estimate.isDisabled}
          // value={}
          isInvalid={!!senderAccount.privateKey.error}
          feedback={senderAccount.privateKey.error}
          onChange={(e) => senderAccount.privateKey.handleChange(e.target.value)}
        />

        <div className="mt-5 flex justify-center">
          <Button className="btn-primary" size={ButtonSize.XL}
                  disabled={send.isDisabled}
                  onClick={() => send.handleSend(closeModal)}
          >
            Ok
          </Button>
        </div>
      </Modal>
    </>
  )
}