import React, {useMemo, useState} from "react";
import {IWeb3Facade} from "../facades/IWeb3Facade";
import {IConsolidationToolView} from "../Views/ConsolidationToolView";
import {
  AddressType, EstimateResultType,
  IBalanceData,
  IBalanceTokenData, IDataForGenerateTransactions, IDataForSendTransactions,
  IGeneralTxData,
  IMapValueByAddress,
  ITokenDict,
  ITransactionPriorityEnum,
  NetworkCurrencyEnum,
  NetworkTitleEnum,
  PrivateKeyType,
  ReceiverAddressType,
  StatusTxEnum
} from "../types";
import {CheckInfo, TransactionInfo} from "../../../components/Table";
import {IWeb3TokenFacade} from "../facades/IWeb3TokenFacade";
import {fromUnitToToken, GasHelper} from "../../../helpers";

function isWeb3TokenFacade(facadeByCurrency: IWeb3Facade | IWeb3TokenFacade): facadeByCurrency is IWeb3TokenFacade {
  return typeof facadeByCurrency.fetchTokenInfo === "function"
}

function withFacade(WrappedComponent: React.FC<IConsolidationToolView>, facadeByCurrency: IWeb3Facade | IWeb3TokenFacade) {

  return () => {
    const networkCurrency = useMemo(() => {
      return NetworkCurrencyEnum[facadeByCurrency.network]
    }, [facadeByCurrency]);

    const [selectedCurrency, setSelectedCurrency] = useState<keyof ITokenDict | string>(networkCurrency)
    const [selectedCurrencyError, setSelectedCurrencyError] = useState<string | null>(null)
    const [selectedCurrencyIsLoading, setSelectedCurrencyIsLoading] = useState<boolean>(false)
    const [tokenDict, setTokenDict] = useState<ITokenDict>(facadeByCurrency.tokensDict)
    const [receiverAddress, setReceiverAddress] = useState<ReceiverAddressType | null>(null)
    const [privateKeysError, setPrivateKeysError] = useState<string | null>(null)
    const [estimateError, setEstimateError] = useState<string | null>(null)
    const [privateKeyByAddress, setPrivateKeyByAddress] = useState<IMapValueByAddress<PrivateKeyType>>(new Map())
    const [isValidatingPrivateKeys, setIsValidatingPrivateKeys] = useState<boolean>(false)
    const [transactionDataByAddress, setTransactionDataByAddress] = useState<IMapValueByAddress<IGeneralTxData>>(new Map())
    const [currentTransactionPriority, setCurrentTransactionPriority] = useState<keyof ITransactionPriorityEnum>(facadeByCurrency.defaultTransactionPriority)
    const [balanceDataByAddress, setBalanceDataByAddress] = useState<IBalanceData | IBalanceTokenData>({balanceByAddress: new Map()})
    const [feeDataByAddress, setFeeDataByAddress] = useState<IMapValueByAddress<BigInt>>(new Map())
    const [isLoadingEstimate, setIsLoadingEstimate] = useState<boolean>(false)

    const [txHashByAddress, setTxHashByAddress] = useState<IMapValueByAddress>(new Map())
    const [isProcessingSend, setIsProcessingSend] = useState<boolean>(false)
    const [isSuccessSend, setIsSuccessSend] = useState<true | undefined>()
    const [errorSend, setErrorSend] = useState<Error | undefined>()

    const tokenAddress = useMemo(() => {
      return tokenDict[selectedCurrency].address
    }, [selectedCurrency, tokenDict]);

    const toToken = useMemo(() => fromUnitToToken(tokenDict[selectedCurrency].decimal), [tokenDict, selectedCurrency])


    const receiverAddressError = useMemo(() => {
      if (!receiverAddress?.length || facadeByCurrency.validateAddress(receiverAddress)) return null

      return 'Address is invalid!'
    }, [receiverAddress]);


    const tableRows: TransactionInfo[] | CheckInfo [] = useMemo(() => {
      const resultTableData: CheckInfo | TransactionInfo[] = []

      privateKeyByAddress.forEach((privateKey, address) => {
        const {balanceByAddress, balanceTokenByAddress} = balanceDataByAddress

        resultTableData.push({
          status: txHashByAddress.has(address) ? StatusTxEnum.SUCCESS : StatusTxEnum.SKIP,
          address: address,
          privateKey: privateKey,
          balance: balanceByAddress.has(address) ? facadeByCurrency.toBaseCurrencyFromUnit(balanceByAddress.get(address)!).toFixed(tokenDict[networkCurrency].decimal) : 0,
          balanceToken: balanceTokenByAddress?.has(address) ? toToken(balanceTokenByAddress?.get(address)!) : 0,
          feeInBaseCurrency: feeDataByAddress.has(address) ? facadeByCurrency.toBaseCurrencyFromUnit(feeDataByAddress.get(address)!).toFixed(tokenDict[networkCurrency].decimal) : 0,
          txHash: txHashByAddress.get(address)
        })
      })
      return resultTableData
    }, [balanceDataByAddress, privateKeyByAddress, feeDataByAddress, txHashByAddress, toToken]);

    const recipientsAndValuesForCharge = useMemo(() => {
      const _recipientsAndValues: IMapValueByAddress<bigint> = new Map()
      if (selectedCurrency === networkCurrency) return _recipientsAndValues
      for (const address of feeDataByAddress.keys()) {
        const {balanceByAddress} = balanceDataByAddress
        const balance = balanceByAddress.get(address)

        const fee = feeDataByAddress.get(address)

        if (balance < fee) {
          _recipientsAndValues.set(address, BigInt(GasHelper.gasPricePlusPercent(fee, 20) - balance))
        }
      }
      return _recipientsAndValues
    }, [feeDataByAddress])

    const isDisabledEstimate = !!(!receiverAddress || receiverAddressError || privateKeysError || !privateKeyByAddress.size || privateKeyByAddress.size > facadeByCurrency.getLimitPrivateKeys(tokenAddress))
    const isDisabledCharge = isDisabledEstimate || estimateError || privateKeyByAddress.size > facadeByCurrency.getLimitPrivateKeys(tokenAddress)
    const isDisabledSend = isDisabledEstimate || recipientsAndValuesForCharge.size || estimateError || !(transactionDataByAddress.size > 0)

    async function toAddress(prk: Set<PrivateKeyType>) {
      setPrivateKeysError(null)
      const _accountsAndKeys: IMapValueByAddress = new Map()
      setIsValidatingPrivateKeys(true)

      prk.forEach(function (key) {
        try {
          const {address, privateKey} = facadeByCurrency.privateKeyToAccount(key)
          if (address !== receiverAddress) {
            _accountsAndKeys.set(address, privateKey)
          }
        } catch (error: any) {
          setPrivateKeysError(error?.message || `Private key ${key} is invalid`)
        }
      })
      setPrivateKeyByAddress(_accountsAndKeys)

      setIsValidatingPrivateKeys(false)
      if (_accountsAndKeys.size > facadeByCurrency.getLimitPrivateKeys(tokenAddress)) {
        setPrivateKeysError(`You entered more private keys than specified in the limit. The limit is ${facadeByCurrency.getLimitPrivateKeys(tokenAddress)}.`)
        return
      }
    }

    function __fetchTokenData(addressInput: AddressType) {
      if (!facadeByCurrency.fetchTokenInfo) return

      setSelectedCurrencyIsLoading(true)
      facadeByCurrency.fetchTokenInfo(addressInput)
        .then(tokenData => {
          const _tokenDict = {...tokenDict}
          _tokenDict[tokenData.symbol] = {..._tokenDict[tokenData.symbol] ?? {}, ...tokenData}
          setTokenDict(_tokenDict)
          setSelectedCurrency(tokenData.symbol)
        })
        .catch(error => {
          console.log('error?.message', error?.message)
          setSelectedCurrencyError(error?.message)
        })
        .finally(() => {
          setSelectedCurrencyIsLoading(false)
        })
    }

    function handleSelectCurrency(currencyInput: keyof ITokenDict | string) {
      setSelectedCurrencyError(null)
      if (currencyInput !== networkCurrency) {
        if (tokenDict.hasOwnProperty(currencyInput)) {
          __fetchTokenData(tokenDict[currencyInput].address!)
        }
      } else {
        setSelectedCurrency(currencyInput)
      }
    }

    function handleAddCurrency(addressInput: AddressType) {
      if (!facadeByCurrency.validateAddress(addressInput)) {
        setSelectedCurrencyError('Address is invalid!')
        return
      }
      setSelectedCurrencyError(null)
      __fetchTokenData(addressInput)
    }

    async function _fetchBalance() {
      let _balanceDataByAddress: IBalanceData = {
        balanceByAddress: new Map()
      };
      const promises: Promise<boolean>[] = [];
      const chunkSize = facadeByCurrency.getAddressesChunkSize(tokenAddress);
      const chunks: Set<AddressType>[] = [];
      let currChunkSet = new Set<AddressType>();
      const privateKeysSet = new Set(privateKeyByAddress.keys());

      privateKeysSet.forEach((privateKey) => {
        if (currChunkSet.size == chunkSize) {
          chunks.push(currChunkSet);
          currChunkSet = new Set<AddressType>();
          currChunkSet.add(privateKey);
        } else {
          currChunkSet.add(privateKey);
        }
      });
      if (currChunkSet.size) {
        chunks.push(currChunkSet);
      }
      for (const chunk of chunks) {
        promises.push(new Promise((resolve) => {
          facadeByCurrency.fetchBalanceDataByAddress(chunk, tokenAddress)
            .then((result) => {
              if (!_balanceDataByAddress.balanceByAddress.size) {
                _balanceDataByAddress.balanceByAddress = result.balanceByAddress
              } else {
                result.balanceByAddress.forEach((value, key) => {
                  _balanceDataByAddress.balanceByAddress.set(key, value);
                });
              }
              if (!_balanceDataByAddress?.balanceTokenByAddress && !_balanceDataByAddress?.balanceTokenByAddress?.size) {
                _balanceDataByAddress.balanceTokenByAddress = result.balanceTokenByAddress
              } else {
                result.balanceTokenByAddress?.forEach((value, key) => {
                  _balanceDataByAddress.balanceTokenByAddress?.set(key, value);
                });
              }
              resolve(true);
            })
            .catch(error => {
              setIsLoadingEstimate(false)
              setPrivateKeysError(error?.message)
              console.error(`${facadeByCurrency.constructor.name} -> fetchBalanceDataByAddress=>`, error)
              resolve(true)
            })
        }));
      }

      await Promise.all(promises);

      setBalanceDataByAddress(_balanceDataByAddress)
      return _balanceDataByAddress
    }

    async function handleEstimate() {
      if (isDisabledEstimate) return
      setErrorSend(undefined)
      setEstimateError(null)
      setIsLoadingEstimate(true)

      const _balanceDataByAddress = await _fetchBalance()
      if (_balanceDataByAddress === null) return

      const _estimateResult: EstimateResultType = {
        feeDataByAddress: new Map(),
        txDataByAddress: new Map()
      }
      const promises: Promise<boolean>[] = [];
      const chunkSize = facadeByCurrency.getAddressesChunkSize(tokenAddress);
      const chunks: Array<IDataForGenerateTransactions> = [];
      let currBalanceChunkMap: IBalanceData = {
        balanceByAddress: new Map(),
      };
      if (tokenAddress) {
        currBalanceChunkMap.balanceTokenByAddress = new Map();
        _balanceDataByAddress.balanceTokenByAddress?.forEach((value, key) => {
          if (currBalanceChunkMap.balanceTokenByAddress?.size == chunkSize) {
            chunks.push({
              balanceDataByAddress: currBalanceChunkMap,
              privateKeyByAddress,
              transactionPriority: currentTransactionPriority,
              receiverAddress,
            });
            currBalanceChunkMap = {
              balanceByAddress: new Map(),
              balanceTokenByAddress: new Map()
            };
            currBalanceChunkMap.balanceTokenByAddress?.set(key, value);
            currBalanceChunkMap.balanceByAddress.set(key, _balanceDataByAddress.balanceByAddress.get(key)!);
          } else {
            currBalanceChunkMap.balanceTokenByAddress?.set(key, value);
            currBalanceChunkMap.balanceByAddress.set(key, _balanceDataByAddress.balanceByAddress.get(key)!);
          }
        });
      } else {
        _balanceDataByAddress.balanceByAddress.forEach((value, key) => {
          if (currBalanceChunkMap.balanceByAddress.size == chunkSize) {
            chunks.push({
              balanceDataByAddress: currBalanceChunkMap,
              privateKeyByAddress,
              transactionPriority: currentTransactionPriority,
              receiverAddress,
            });
            currBalanceChunkMap = {
              balanceByAddress: new Map()
            };
            currBalanceChunkMap.balanceByAddress?.set(key, value);
          } else {
            currBalanceChunkMap.balanceByAddress?.set(key, value);
          }
        });
      }

      if (currBalanceChunkMap.balanceByAddress.size !== 0 || currBalanceChunkMap.balanceTokenByAddress?.size !== 0) {
        chunks.push({
          balanceDataByAddress: currBalanceChunkMap,
          privateKeyByAddress,
          transactionPriority: currentTransactionPriority,
          receiverAddress,
        });
      }

      for (const key in chunks) {
        const chunk = chunks[key];
        const generateTransactionsPromise = new Promise<boolean>((resolve) => {
          facadeByCurrency.generateTransactions(chunk, tokenAddress)
            .then((result) => {
              result.txDataByAddress.forEach((value, key) => {
                _estimateResult.txDataByAddress.set(key, value);
              });
              result.feeDataByAddress.forEach((value, key) => {
                _estimateResult.feeDataByAddress.set(key, value);
              });
              resolve(true);
            })
            .catch(error => {
              setIsLoadingEstimate(false)
              setEstimateError(error?.message)
              console.error(`${facadeByCurrency.constructor.name} -> generateTransactions=>`, error)
              resolve(true)
            })
        });
        if (key === '0') {
          await generateTransactionsPromise;
        }
        promises.push(generateTransactionsPromise);
      }

      await Promise.all(promises);

      const {
        feeDataByAddress: _feeDataByAddress,
        txDataByAddress: _txDataByAddress
      } = _estimateResult
      setTransactionDataByAddress(_txDataByAddress)
      setFeeDataByAddress(_feeDataByAddress)
      setIsLoadingEstimate(false)
    }

    async function handleSend() {
      if (isDisabledSend) return

      setIsProcessingSend(true)
      setErrorSend(undefined)
      let errorObj: Error | undefined;
      facadeByCurrency.resetGasPrice();

      const resultTxReceiptByAddress: IMapValueByAddress = new Map()
      const promises: Promise<boolean>[] = [];
      const chunkSize = facadeByCurrency.getAddressesChunkSize(tokenAddress);
      const chunks: Array<IDataForSendTransactions> = [];
      let currBalanceChunkMap: IBalanceData = {
        balanceByAddress: new Map(),
      };
      let currTxDataChunkMap: IMapValueByAddress<IGeneralTxData> = new Map()
      if (tokenAddress) {
        currBalanceChunkMap.balanceTokenByAddress = new Map();
        balanceDataByAddress.balanceTokenByAddress?.forEach((balance, address) => {
          if (currBalanceChunkMap.balanceTokenByAddress?.size == chunkSize) {
            chunks.push({
              balanceDataByAddress: currBalanceChunkMap,
              privateKeyByAddress,
              transactionPriority: currentTransactionPriority,
              receiverAddress,
              transactionDataByAddress: currTxDataChunkMap
            });
            currBalanceChunkMap = {
              balanceByAddress: new Map(),
              balanceTokenByAddress: new Map()
            };
            currTxDataChunkMap = new Map()
            if (transactionDataByAddress.has(address)) {
              currBalanceChunkMap.balanceTokenByAddress?.set(address, balance);
              currBalanceChunkMap.balanceByAddress.set(address, balanceDataByAddress.balanceByAddress.get(address)!);
              currTxDataChunkMap.set(address, transactionDataByAddress.get(address)!);
            }
          } else {
            if (transactionDataByAddress.has(address)) {
              currBalanceChunkMap.balanceTokenByAddress?.set(address, balance);
              currBalanceChunkMap.balanceByAddress.set(address, balanceDataByAddress.balanceByAddress.get(address)!);
              currTxDataChunkMap.set(address, transactionDataByAddress.get(address)!);
            }
          }
        });
      } else {
        balanceDataByAddress.balanceByAddress.forEach((balance, address) => {
          if (currBalanceChunkMap.balanceByAddress.size == chunkSize) {
            chunks.push({
              balanceDataByAddress: currBalanceChunkMap,
              privateKeyByAddress,
              transactionPriority: currentTransactionPriority,
              receiverAddress,
              transactionDataByAddress: currTxDataChunkMap
            });
            currBalanceChunkMap = {
              balanceByAddress: new Map()
            };
            currTxDataChunkMap = new Map();
            currBalanceChunkMap.balanceByAddress?.set(address, balance);
            currTxDataChunkMap.set(address, transactionDataByAddress.get(address)!);
          } else {
            currBalanceChunkMap.balanceByAddress?.set(address, balance);
            currTxDataChunkMap.set(address, transactionDataByAddress.get(address)!);
          }
        });
      }

      if (currBalanceChunkMap.balanceByAddress.size !== 0 || currBalanceChunkMap.balanceTokenByAddress?.size !== 0) {
        chunks.push({
          balanceDataByAddress: currBalanceChunkMap,
          privateKeyByAddress,
          transactionPriority: currentTransactionPriority,
          receiverAddress,
          transactionDataByAddress: currTxDataChunkMap
        });
      }

      for (const key in chunks) {
        const chunk = chunks[key];
        const sendTransactionsPromise = new Promise<boolean>((resolve) => {
          facadeByCurrency.sendTransactions(chunk, tokenAddress)
            .then((result) => {
              result.forEach((value, key) => {
                resultTxReceiptByAddress.set(key, value);
              });
              resolve(true);
            })
            .catch(error => {
              errorObj = Error(error?.message || error)
              console.error(`${facadeByCurrency.constructor.name} -> sendTransactions=>`, error)
              setErrorSend(errorObj)
              setIsProcessingSend(false)
              resolve(true)
            })
        });
        if (key === '0') {
          await sendTransactionsPromise;
        }
        promises.push(sendTransactionsPromise);
      }

      await Promise.all(promises);

      setTxHashByAddress(resultTxReceiptByAddress || (new Map()))
      setIsSuccessSend(true)
      setIsProcessingSend(false)

      _fetchBalance()

      setIsProcessingSend(false)
    }


    const props = {
      isWeb3TokenFacade: isWeb3TokenFacade(facadeByCurrency),
      networkCurrency: networkCurrency,
      title: NetworkTitleEnum[facadeByCurrency.network],
      tableData: {
        tableRows: tableRows,
        linkForTxScan: facadeByCurrency.linkForTxScan,
      },
      transactionPriority: {
        value: currentTransactionPriority,
        setCurrentTransactionPriority,
        options: facadeByCurrency.transactionPriorityOptions
      },
      receiverAddress: {
        value: receiverAddress,
        setReceiverAddress,
        error: receiverAddressError
      },
      privateKeys: {
        isValidating: isValidatingPrivateKeys,
        error: privateKeysError,
        limit: facadeByCurrency.getLimitPrivateKeys(tokenAddress),
        isLimit: privateKeyByAddress.size > facadeByCurrency.getLimitPrivateKeys(tokenAddress),
        privateKeyByAddress,
        privateKeysToAddresses: toAddress
      },
      estimate: {
        handleEstimate,
        isDisabled: isDisabledEstimate,
        isLoading: isLoadingEstimate,
        error: estimateError,
        balanceDataByAddress,
        feeDataByAddress,
      },
      charge: {
        recipientsAndValues: recipientsAndValuesForCharge,
        isDisabled: isDisabledCharge
      },
      send: {
        txHashByAddress,
        handleSend,
        isDisabled: isDisabledSend,
        isSuccess: isSuccessSend,
        isProcessing: isProcessingSend,
        error: errorSend ? errorSend.message : null,
      },
      selectedCurrency: {
        value: selectedCurrency,
        handleSelectCurrency,
        handleAddCurrency,
        error: selectedCurrencyError,
        isLoading: selectedCurrencyIsLoading,
        dataInfo: tokenDict[selectedCurrency] || null,
        tokensDict: tokenDict,
      },
    }

    return (
      <WrappedComponent {...props} />
    );

  }
}

export {withFacade}