import {AddressHexStr, IContractAbiFragment} from "../../models/chainScan.models";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ZERO_HEX} from "../web3/web3";
import {chainScanApi} from "./chainScan.api";

interface ConfigState extends ITokenScanConfig {
  abi: IContractAbiFragment[],
  contractAddress: AddressHexStr,
}

const initialState: ConfigState = {
  abi: [],
  contractAddress: ZERO_HEX,
  linkHttpProvider: '',
  linkForTxScan: '',
  linkForGasPriceApi: '',
  linkForScanApi: '',
  keyForScanApi: '',
}

export interface ITokenScanConfig {
  linkHttpProvider: string,
  linkForTxScan: string,
  linkForScanApi: string,
  linkForGasPriceApi: string,
  keyForScanApi: string
}

export const chainConfigSlice = createSlice({
  name: 'chainConfig',
  initialState: initialState,
  reducers: {
    setContractAbi(state, action: PayloadAction<IContractAbiFragment[]>) {
      state.abi = action.payload
    },
    resetContractAbi(state) {
      state.abi = initialState.abi
    },
    setTokenScanConfig(state, action: PayloadAction<ITokenScanConfig>) {
      state.linkHttpProvider = action.payload.linkHttpProvider
      state.linkForTxScan = action.payload.linkForTxScan
      state.linkForGasPriceApi = action.payload.linkForGasPriceApi
      state.linkForScanApi = action.payload.linkForScanApi
      state.keyForScanApi = action.payload.keyForScanApi
    },
    resetTokenScanConfig(state) {
      state.linkHttpProvider = initialState.linkHttpProvider
      state.linkForTxScan = initialState.linkForTxScan
      state.linkForGasPriceApi = initialState.linkForGasPriceApi
      state.linkForScanApi = initialState.linkForScanApi
      state.keyForScanApi = initialState.keyForScanApi
    },
    setContractAddress(state, action: PayloadAction<AddressHexStr>) {
      state.contractAddress = action.payload
    },
    resetContractAddress(state) {
      state.contractAddress = initialState.contractAddress
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      chainScanApi.endpoints?.getContractABI.matchFulfilled,
      (state, {payload}) => {
        if (payload.status) {
          state.abi = payload.result
        }
      }
    )
  }
})


export const {reducer: chainConfigReducer, actions: chainConfigActions} = chainConfigSlice
