import {BaseQueryFn, createApi, fetchBaseQuery, FetchBaseQueryError} from "@reduxjs/toolkit/query/react";
import {
  AddressHexStr,
  ApiScanResponse,
  ApiScanTransformResponse,
  IContractAbiFragment,
  ITokenInfo,
  ITransferEvents
} from "../../models/chainScan.models";
import {RootState} from "../index";

interface IParams {
  module: string,
  action: string,
  apikey: string,

  [key: string]: string | number | never
}

interface ArgForQuery {
  url: 'api',
  params: IParams
}

const dynamicBaseQuery: BaseQueryFn<ArgForQuery,
  unknown,
  FetchBaseQueryError
> = async (args: ArgForQuery, apiBQ, extraOptions) => {
  const {chainConfig} = apiBQ.getState() as RootState

  args.params = {...args.params, apikey: chainConfig.keyForScanApi}
  // construct a dynamically generated the baseUrl
  const baseUrl = chainConfig.linkForScanApi;
  const rawBaseQuery = fetchBaseQuery({
    baseUrl
  });
  return rawBaseQuery(args, apiBQ, extraOptions);
}

//TODO refactor, convert query to fn with validation of status

export const chainScanApi = createApi({
  reducerPath: 'chainScan/api',
  baseQuery: dynamicBaseQuery,
  endpoints: (build) => ({
    getTokenInfo: build.query<ApiScanTransformResponse<ITokenInfo | null>, string>({
      query: (contractAddress: string) => ({
        url: 'api',
        params: {
          module: 'account',
          action: 'tokentx',
          contractaddress: contractAddress,
          apikey: '__api_key',
          page: 1,
          offset: 1
        }
      }),
      transformResponse: (rawResult: ApiScanResponse<ITransferEvents[]>) => {
        const resultArr: ITokenInfo[] | null = Array.isArray(rawResult.result) ? rawResult.result.map(item => ({
          symbol: item.tokenSymbol,
          title: item.tokenName,
          img: '',
          rate: '',
          address: item.contractAddress as AddressHexStr,
          link: '',
          group: '',
          website: '',
          desc: '',
          decimal: +item.tokenDecimal
        })) : null
        return {
          ...rawResult,
          status: parseInt(rawResult.status) === 1,
          result: resultArr ? resultArr[0] : null
        }
      }
    }),
    getContractABI: build.query<ApiScanTransformResponse<IContractAbiFragment[]>, AddressHexStr>({
      query: (contractAddress: AddressHexStr) => ({
        url: 'api',
        params: {
          module: 'contract',
          action: 'getabi',
          address: contractAddress,
          apikey: '__api_key'
        }
      }),
      transformResponse: (rawResult: ApiScanResponse<string>): ApiScanTransformResponse<IContractAbiFragment[]> => {
        if (rawResult.status) {
          return {
            ...rawResult,
            status: parseInt(rawResult.status) === 1,
            result: JSON.parse(rawResult.result)
          }
        }
        return {
          ...rawResult,
          status: parseInt(rawResult.status) === 1,
          result: []
        }
      }
    }),
  })
})

export const {
  useGetTokenInfoQuery,
  useLazyGetTokenInfoQuery,
} = chainScanApi