import React, {useRef} from "react";
import {Tooltip} from "react-tooltip";
import {toast} from "react-toastify";
import {Badge} from "../ui";
import {BadgeType} from "../ui/Badge";
import {NetworkCurrencyEnum, NetworkType, StatusTxEnum} from "../../pages/ConsolidationTool/types";
import {ViewportList} from "react-viewport-list";

interface PropsTable {
  tokenSymbol: string,
  linkForTxScan: string,
  networkCurrency: typeof NetworkCurrencyEnum[NetworkType],
  isTransactionInfo: boolean,
  listInfo: TransactionInfo[] | CheckInfo []
}

export interface CheckInfo {
  privateKey: string,
  address: string,
  balance: string | number | BigInt,
  balanceToken: number | BigInt
  feeInBaseCurrency: string | number | BigInt,
  status: StatusTxEnum,
  txHash: undefined
}

export interface TransactionInfo extends Omit<CheckInfo, 'txHash'> {
  txHash: string | undefined
}

const statusAdapter = function (status: TransactionInfo['status']) {
  const statusType: Record<StatusTxEnum, BadgeType> = {
    [StatusTxEnum.SUCCESS]: 'success',
    [StatusTxEnum.ERROR]: 'danger',
    [StatusTxEnum.SKIP]: 'info',
  }

  return statusType?.[status] || 'info'
}

export function Table(props: PropsTable) {
  const {
    listInfo,
    tokenSymbol,
    linkForTxScan,
    isTransactionInfo,
    networkCurrency
  } = props

  const tableRef = useRef(null)

  return (
    <div className="table-container">
      <div className="table-row table-row-heading">
        {
          isTransactionInfo ? (
            <div className="row-item">
              Status
            </div>
          ) : null
        }

        <div className="row-item">
          PK
        </div>
        <div className="row-item">
          Address
        </div>
        {tokenSymbol && <div className="row-item">
            Balance ({tokenSymbol})
        </div>}
        <div className="row-item">
          Balance ({networkCurrency})
        </div>
        <div className="row-item">
          Fee min ({networkCurrency})
        </div>
        {
          isTransactionInfo ? (
            <div className="row-item">
              Link tx
            </div>
          ) : null
        }
      </div>

      <div className="table-body">
        <ViewportList
          ref={tableRef}
          items={listInfo}
        >
          {
            (item) => {
              const cutPrivateKey = item.privateKey?.slice(0, 4) + '...' + item.privateKey?.slice(-4)
              const cutAccount = item.address?.slice(0, 4) + '...' + item.address?.slice(-4)

              return (
                <div
                  key={`${item.address}_${item.feeInBaseCurrency}_${item.status}`}
                  className="table-row"
                >
                  {isTransactionInfo ? (
                    <div className="row-item">
                      <Badge type={statusAdapter(item.status)}>
                        {item.status}
                      </Badge>
                    </div>
                  ) : null}
                  <div className="row-item">
                    <div
                      className="cursor-pointer hover:opacity-70 transition-all"
                      onClick={() => {
                        navigator.clipboard.writeText(item.privateKey)
                        toast.success('Copied: ' + item.privateKey)
                      }}
                    >
                      <a className="cursor-pointer" id={'privateKey-' + item.privateKey}>{cutPrivateKey}</a> <i
                      className="icon-copy"/>
                    </div>
                    <Tooltip
                      anchorSelect={'#privateKey-' + item.privateKey}
                      content={item.privateKey}
                    />
                  </div>
                  <div className="row-item">
                    <div
                      className="cursor-pointer hover:opacity-70 transition-all"
                      onClick={() => {
                        navigator.clipboard.writeText(item.address)
                        toast.success('Copied: ' + item.address)
                      }}
                    >
                      <a className="cursor-pointer" id={'address-' + item.address}>{cutAccount}</a> <i
                      className="icon-copy"/>
                    </div>
                    <Tooltip
                      anchorSelect={'#address-' + item.address}
                      content={item.address}
                    />
                  </div>
                  {tokenSymbol && <div className="row-item">{item.balanceToken.toString()}</div>}
                  <div className={`row-item${+item.balance < +item.feeInBaseCurrency ? ' text-red-700' : ''}`}>
                    {item.balance.toString()}
                  </div>
                  <div className="row-item">{item.feeInBaseCurrency.toString()}</div>
                  {isTransactionInfo && <div className="row-item">
                    {item.txHash ?
                      <a href={`${linkForTxScan}${item.txHash}`} target="_blank" className="text-blue-700">txHash</a>
                      : '-'}
                  </div>}
                </div>
              )
            }
          }
        </ViewportList>
      </div>
    </div>
  )
}