import React, {useMemo, useState} from "react";
import {
  AddressType,
  IMapValueByAddress,
  NetworkCurrencyEnum,
  NetworkTitleEnum,
  PrivateKeyType
} from "../../ConsolidationTool/types";
import {IDisperseToolView} from "../Views/DisperseToolView";
import {IWeb3DisperseFacade} from "../facades/IWeb3DisperseFacade";

function withFacade(WrappedComponent: React.FC<IDisperseToolView>, facadeByCurrency: IWeb3DisperseFacade, initData?: {
  recipientsAndValuesForChargeInUnit: IMapValueByAddress<bigint>
}) {
  const initAmountInUnit = initData?.recipientsAndValuesForChargeInUnit || new Map<AddressType, BigInt>([])
  const initAmountInBase = new Map<AddressType, number>([])
  initAmountInUnit.forEach((value, address) => {
    initAmountInBase.set(address, facadeByCurrency.toBaseCurrencyFromUnit(value))
  })

  return () => {
    const [amountInUnitByReceiver, setAmountInUnitByReceiver] = useState(initAmountInUnit)
    const [amountInBaseCurrencyByReceiver, setAmountInBaseCurrencyByReceiver] = useState(initAmountInBase)
    const [txHashByAddress, setTxHashByAddress] = useState<IMapValueByAddress>(new Map())
    const [isProcessingSend, setIsProcessingSend] = useState<boolean>(false)
    const [isSuccessSend, setIsSuccessSend] = useState<true | undefined>()
    const [errorSend, setErrorSend] = useState<Error | undefined>()

    const [isSyncAmount, setIsSyncAmount] = useState(false)
    const [isEstimatingDisperse, setIsEstimatingDisperse] = useState(false)

    const [receiverInput, setReceiverInput] = useState<AddressType | null>(null)
    const [amountInCurrencyInput, setAmountInput] = useState<number | string | null>(null)
    const [feeEstimated, setFeeEstimated] = useState<{
      optimizedFeeInUnit?: bigint,
      notOptimizedFeeInUnit: bigint
    }>({notOptimizedFeeInUnit: BigInt(0)})
    const [isOptimizedApproach, setIsOptimizedApproach] = useState<boolean>(false)


    const [senderAddressInput, setSenderAddressInput] = useState<AddressType | null>(null)
    const [senderBalanceInUnit, setSenderBalanceInUnit] = useState<{
      address: AddressType | null,
      balance: bigint
    }>({address: null, balance: BigInt(0)})


    const [senderAccountPrivateKey, setSenderAccountPrivateKey] = useState<PrivateKeyType | null>(null)

    const senderPrivateKeyInputError = useMemo(() => {
      if (!senderAccountPrivateKey?.length) return null
      try {
        const account = facadeByCurrency.privateKeyToAccount(senderAccountPrivateKey.trim())
        if (account.address !== senderAddressInput) {
          return `Your private key does not match with entered sender address!`
        }
      } catch (e: any) {
        return e?.message || `Private key is invalid!`
      }
      return null
    }, [senderAccountPrivateKey, senderAddressInput]);

    const senderAddressInputError = useMemo(() => {
      setSenderBalanceInUnit({address: senderAddressInput, balance: BigInt(0)})
      if (!senderAddressInput?.length) return null
      if (!facadeByCurrency.validateAddress(senderAddressInput)) return 'Address is invalid!'

      facadeByCurrency.fetchBalanceInUnit(senderAddressInput)
        .then(balanceInUnit => {
          setSenderBalanceInUnit({address: senderAddressInput, balance: balanceInUnit})
        })
        .catch(error => {
          console.error('fetchBalanceInUnit=>', error?.message || 'Smth was wrong!')
        })
      return null
    }, [senderAddressInput]);

    const receiverInputError = useMemo(() => {
      if (!receiverInput?.length) return null
      if (!facadeByCurrency.validateAddress(receiverInput)) return 'Address is invalid!'

      return null
    }, [receiverInput]);

    const totalSendInUnit = useMemo(() => {
      let __total: bigint = BigInt(0);
      if (!amountInUnitByReceiver.size) return __total

      amountInUnitByReceiver.forEach((value, key) => __total += value)
      return __total
    }, [amountInUnitByReceiver])

    const currentFeeInUnit = useMemo(() => {
      return isOptimizedApproach ? (feeEstimated.optimizedFeeInUnit | BigInt(0)) : feeEstimated.notOptimizedFeeInUnit
    }, [isOptimizedApproach, feeEstimated])

    const senderBalanceError = useMemo(() => {
      if (senderBalanceInUnit.address) {
        if (senderBalanceInUnit.balance <= (currentFeeInUnit + totalSendInUnit)) {
          return 'Insufficient funds for transfer'
        }
      }
      return null
    }, [senderBalanceInUnit, currentFeeInUnit, totalSendInUnit])

    const isLimitAddresses = useMemo(() => {
      return amountInBaseCurrencyByReceiver.size > facadeByCurrency.getLimitReceiver()
    }, [amountInBaseCurrencyByReceiver, facadeByCurrency])

    const estimateIsDisable = !senderAddressInput || !!senderAddressInputError || amountInUnitByReceiver.size === 0 || isEstimatingDisperse || isLimitAddresses
    const sendIsDisable = estimateIsDisable || senderBalanceError || senderAddressInputError || (senderAccountPrivateKey && senderPrivateKeyInputError)


    function editAmountByAddress(address: AddressType, amount: number) {
      if (isSyncAmount) {
        amountInUnitByReceiver.forEach((_amount, address) => {
          amountInUnitByReceiver.set(address, facadeByCurrency.toUnitFromBaseCurrency(amount))
          amountInBaseCurrencyByReceiver.set(address, amount)
        })
      } else {
        amountInUnitByReceiver.set(address, facadeByCurrency.toUnitFromBaseCurrency(amount))
        amountInBaseCurrencyByReceiver.set(address, amount)
      }
      setAmountInUnitByReceiver(new Map(amountInUnitByReceiver))
      setAmountInBaseCurrencyByReceiver(new Map(amountInBaseCurrencyByReceiver))
    }

    function deleteReceiver(address: AddressType) {
      amountInUnitByReceiver.delete(address)
      setAmountInUnitByReceiver(new Map(amountInUnitByReceiver))

      amountInBaseCurrencyByReceiver.delete(address)
      setAmountInBaseCurrencyByReceiver(new Map(amountInBaseCurrencyByReceiver))
    }

    function addReceiver() {
      if (receiverInput && amountInCurrencyInput) {
        editAmountByAddress(receiverInput, Number(amountInCurrencyInput))
        __clearInput()
      }
    }

    function __clearInput() {
      setSenderAccountPrivateKey(null)
      setReceiverInput(null)
      setAmountInput(null)
      setIsSuccessSend(undefined)
    }


    async function handleEstimate() {
      if (isEstimatingDisperse || isLimitAddresses || !senderBalanceInUnit.address) return
      setIsEstimatingDisperse(true)
      setIsSuccessSend(undefined)
      const {optimizedFeeInUnit, notOptimizedFeeInUnit} = await facadeByCurrency.estimateTransactions({
        amountInUnitByReceiver
      })

      setFeeEstimated({
        optimizedFeeInUnit,
        notOptimizedFeeInUnit
      })

      setIsEstimatingDisperse(false)
    }

    async function handleSend(callBack: () => void) {
      if (sendIsDisable || !senderAccountPrivateKey) return

      setIsProcessingSend(true)

      const senderAccount = facadeByCurrency.privateKeyToAccount(senderAccountPrivateKey!)
      setSenderAccountPrivateKey(null)

      try {
        const resultTxReceiptByAddress = await facadeByCurrency.sendTransactions({
          amountInUnitByReceiver,
          senderAccount
        })
        setTxHashByAddress(resultTxReceiptByAddress)
        setIsSuccessSend(true)
        callBack()
      } catch (e) {
        setErrorSend(e?.message)
      }
      setIsProcessingSend(false)
    }

    /**
     * parsing ctrl+v or paste list of value "address amountInCurrency" from exel
     */
    function enterReceiver(value: string | AddressType) {
      const regExp = /(0x[\d\w]{40})[\s]{0,}([\d]{0,}[\.]{0,}[\d]{0,})/g; //TODO it`s works only for EVM`s networks !!!
      const lines = (value ? Array.from(value.matchAll(regExp)) : []) as [string, AddressType, number | ""][]
      if (lines.length > 1) {
        for (const line of lines) {
          const [allMatch, account, value] = line
          editAmountByAddress(account, value || 0)
        }
        __clearInput()
      } else {
        /**
         * default behavior
         */
        setReceiverInput(value)
      }
    }

    const props = {
      networkCurrency: NetworkCurrencyEnum[facadeByCurrency.network],
      title: NetworkTitleEnum[facadeByCurrency.network],
      linkForTxScan: facadeByCurrency.linkForTxScan,
      amountByReceiver: {
        setAmountByReceiver: editAmountByAddress,
        removeReceiver: deleteReceiver,
        value: amountInBaseCurrencyByReceiver,
        total: facadeByCurrency.toBaseCurrencyFromUnit(totalSendInUnit),
        limit: facadeByCurrency.getLimitReceiver(),
        isLimit: isLimitAddresses,
        error: null,
      },
      receiverAndAmountInput: {
        receiver: {
          value: receiverInput,
          handleChange: enterReceiver,
          error: receiverInputError
        },
        amount: {
          value: amountInCurrencyInput,
          handleChange: setAmountInput
        },
        addReceiver,
      },
      syncAmount: {
        isSync: isSyncAmount,
        setIsSyncAmount
      },
      senderAccount: {
        address: {
          value: senderAddressInput,
          handleChange: setSenderAddressInput,
          error: senderAddressInputError,
        },
        balance: {
          value: facadeByCurrency.toBaseCurrencyFromUnit(senderBalanceInUnit.balance),
          error: senderBalanceError
        },
        privateKey: {
          handleChange: setSenderAccountPrivateKey,
          error: senderPrivateKeyInputError
        }
      },
      estimate: {
        handleEstimate,
        isDisabled: estimateIsDisable,
        error: null,
        fee: {
          handleSelect: setIsOptimizedApproach,
          value: facadeByCurrency.toBaseCurrencyFromUnit(currentFeeInUnit),
          options: [
            feeEstimated.notOptimizedFeeInUnit ? {
              label: 'Single transactions',
              isOptimized: false,
              fee: facadeByCurrency.toBaseCurrencyFromUnit(feeEstimated.notOptimizedFeeInUnit),
              isSelected: isOptimizedApproach === false,
            } : null,
            feeEstimated.optimizedFeeInUnit ? {
              fee: facadeByCurrency.toBaseCurrencyFromUnit(feeEstimated.optimizedFeeInUnit || 0),
              isOptimized: true,
              label: 'Multi-transaction',
              isSelected: isOptimizedApproach === true,
            } : null
          ].filter(Boolean),
        },
        isLoading: isEstimatingDisperse
      },
      send: {
        txHashByAddress,
        handleSend,
        total: facadeByCurrency.toBaseCurrencyFromUnit(totalSendInUnit + currentFeeInUnit),
        isDisabled: sendIsDisable,
        isSuccess: isSuccessSend,
        isProcessing: isProcessingSend,
        error: errorSend
      },
    }

    return (
      <WrappedComponent {...props} />
    );

  }
}

export {withFacade}