import {createApi, fakeBaseQuery} from "@reduxjs/toolkit/query/react";
import {HexStr, setProviderWeb3, ZERO_HEX} from "./web3";
import {
  AddressHexStr,
  IAllInfoByAddresses,
  IBalanceOfByContract,
  IRpcResponse,
  ITxDataByAccount,
  ITxTokenBeforeEstimateGas,
  ITxTokenData,
  KeyOfInfoByAddressType,
  ValueByAddressType,
} from "../../models/chainScan.models";
import {JsonRpcError} from "web3-types/src/json_rpc_types";
import {RootState} from "../index";
import {chainScanApi} from "../common/chainScan.api";
import {GasHelper} from "../../helpers";

export const web3Api = createApi({
  reducerPath: 'web3/api',
  baseQuery: fakeBaseQuery(),
  endpoints: builder => ({
    getBatchGasEstimate: builder.query<ITxDataByAccount<ITxTokenData>, ITxDataByAccount<ITxTokenBeforeEstimateGas>>({
      queryFn: async (dataTxArr, apiBQ) => {
        const {chainConfig} = apiBQ.getState() as RootState
        const {BatchRequest, estimateGas} = setProviderWeb3(chainConfig.linkHttpProvider)

        const responseData: ITxDataByAccount<ITxTokenData> = {}
        const batchGasEstimate = new BatchRequest();
        let account: AddressHexStr;
        for (account in dataTxArr) {
          batchGasEstimate.add(estimateGas.request(dataTxArr[account]))
        }

        try {
          const gasArr = await batchGasEstimate.execute({timeout: 2000})
          for (let item of gasArr) {
            if (item.error) {
              const errorData = item.error as JsonRpcError
              return {error: Error(errorData.message + `[${errorData.code}]`)}
            }
            let itemSuccess = item as IRpcResponse
            const originGas = estimateGas.outputFormatter(itemSuccess.result)
            account = itemSuccess.id as AddressHexStr
            responseData[account] = {
              gas: GasHelper.gasPay(originGas),
              ...dataTxArr[account]
            }
          }
          return {data: responseData}
        } catch (e) {
          return {error: e}
        }
      },
    }),
    getGasEstimate: builder.query<ITxTokenData, ITxTokenBeforeEstimateGas>({
      queryFn: async (dataTx, apiBQ) => {
        const {chainConfig} = apiBQ.getState() as RootState
        const {estimateGas} = setProviderWeb3(chainConfig.linkHttpProvider)

        try {
          const gas = await estimateGas(dataTx)
          const originGas = estimateGas.outputFormatter(gas)
          const responseData: ITxTokenData = {
            gas: GasHelper.gasPay(originGas),
            ...dataTx
          }

          return {data: responseData}
        } catch (e: any) {
          if (e?.innerError) {
            return {error: Error(e.innerError.message)}
          }
          return {error: e}
        }
      },
    }),
    getBalance: builder.query<string, AddressHexStr>({
      queryFn: async (account: AddressHexStr, apiBQ) => {
        const {chainConfig} = apiBQ.getState() as RootState
        const {getBalance} = setProviderWeb3(chainConfig.linkHttpProvider)

        try {
          const balance = await getBalance(account)
          return {data: getBalance.outputFormatter(balance).toString()}
        } catch (e) {
          return {error: e}
        }
      },
    }),
    getTransactionCount: builder.query<number, AddressHexStr>({
      queryFn: async (account: AddressHexStr, apiBQ) => {
        const {chainConfig} = apiBQ.getState() as RootState
        const {getTransactionCount} = setProviderWeb3(chainConfig.linkHttpProvider)
        try {
          const nonce = await getTransactionCount(account)
          return {data: getTransactionCount.outputFormatter(nonce)}
        } catch (e) {
          return {error: e}
        }
      },
    }),
    getBatchInfoByAccount: builder.query<IAllInfoByAddresses, AddressHexStr[]>({
      queryFn: async (accounts, apiBQ) => {
        const {chainConfig} = apiBQ.getState() as RootState
        const isSkipContract = !chainConfig.abi.length || chainConfig.contractAddress === ZERO_HEX

        const {
          BatchRequest,
          getTransactionCount,
          getTokenContract,
          getBalance
        } = setProviderWeb3(chainConfig.linkHttpProvider)
        const prefixIdEthBalance: KeyOfInfoByAddressType = `balance`
        const prefixIdTokenBalance: KeyOfInfoByAddressType = `balanceToken`
        const prefixIdNonce: KeyOfInfoByAddressType = `nonce`
        const responseData: IAllInfoByAddresses = {};


        const {balanceOf} = isSkipContract ? {balanceOf: undefined}
          : getTokenContract(chainConfig.abi, chainConfig.contractAddress);


        const batchInfo = new BatchRequest();
        for (let account of accounts) {
          batchInfo.add(getBalance.request(account, prefixIdEthBalance));
          batchInfo.add(getTransactionCount.request(account, prefixIdNonce));
          if (!isSkipContract) {
            batchInfo.add(balanceOf?.request(account, prefixIdTokenBalance)!)
          }
        }

        try {
          const dataInfo = await batchInfo.execute({timeout: 5000})
          for (let item of dataInfo) {
            if (item.error) {
              const errorData = item.error as JsonRpcError
              return {error: Error(errorData.message + `[${errorData.code}]`)}
            }
            let itemSuccess = item as IRpcResponse
            let idParsed: string[] = itemSuccess.id.split('.')
            let idPublicKey: AddressHexStr
            let prefix: string | undefined

            if (idParsed.length > 1) {
              [prefix, idPublicKey] = idParsed as [string, AddressHexStr]
            } else {
              [idPublicKey] = idParsed as [AddressHexStr]
            }

            responseData[idPublicKey] = responseData[idPublicKey] || {}

            if (itemSuccess && prefix === prefixIdEthBalance) {
              responseData[idPublicKey].balance = itemSuccess.result
              if (isSkipContract) {
                responseData[idPublicKey].balanceToken = '0'
              }
            }

            if (itemSuccess && prefix === prefixIdTokenBalance && !isSkipContract) {
              responseData[idPublicKey].balanceToken = balanceOf?.outputFormatter(itemSuccess.result)!
            }

            if (itemSuccess && prefix === prefixIdNonce) {
              responseData[idPublicKey].nonce = itemSuccess.result
            }
          }
          return {data: responseData}
        } catch (e) {
          return {error: e}
        }
      }
    }),
    balanceOfByContract: builder.query<string, IBalanceOfByContract>({
      queryFn: async ({contractAddress, account}, apiBQ) => {
        const {chainConfig} = apiBQ.getState() as RootState

        const {getTokenContract} = setProviderWeb3(chainConfig.linkHttpProvider)

        const {data: dataContractABI} = await apiBQ.dispatch(chainScanApi.endpoints?.getContractABI.initiate(contractAddress))
        if (!dataContractABI?.status) {
          throw Error(dataContractABI?.message)
        }
        const {
          balanceOf,
        } = getTokenContract(dataContractABI?.result, contractAddress);


        try {
          const balanceToken: bigint = await balanceOf(account).call();
          return {data: BigInt(balanceToken).toString()}
        } catch (e) {
          return {error: e}
        }
      },
    }),
    sendBatchSignedTransaction: builder.mutation<ValueByAddressType, ValueByAddressType>({
      queryFn: async (rawTransactionByAccount, apiBQ) => {
        const {chainConfig} = apiBQ.getState() as RootState

        const {sendSignedTransaction, BatchRequest} = setProviderWeb3(chainConfig.linkHttpProvider)

        const resultTxReceipt: ValueByAddressType = {}
        let account: AddressHexStr;
        const batchTx = new BatchRequest();
        for (account in rawTransactionByAccount) {
          batchTx.add(sendSignedTransaction.request(rawTransactionByAccount[account], account));
        }

        try {
          const dataBatchTx = await batchTx.execute({timeout: 3000})
          for (let txResult of dataBatchTx) {
            if (txResult.error) {
              const errorData = txResult.error as JsonRpcError
              return {error: Error(`${errorData.message} [${errorData.code}]`)}
            }
            let itemSuccess = txResult as IRpcResponse
            resultTxReceipt[itemSuccess.id as AddressHexStr] = itemSuccess.result as HexStr
          }

          return {data: resultTxReceipt}
        } catch (e) {
          return {error: e}
        }
      },
    }),
    sendSignedTransaction: builder.mutation<HexStr, HexStr>({
      queryFn: async (rawTransaction, apiBQ) => {
        const {chainConfig} = apiBQ.getState() as RootState
        const {sendSignedTransaction, BatchRequest} = setProviderWeb3(chainConfig.linkHttpProvider)

        const batchTx = new BatchRequest();
        batchTx.add(sendSignedTransaction.request(rawTransaction, '0x1'));

        try {
          const dataBatchTx = await batchTx.execute()
          if (dataBatchTx[0].error) {
            const errorData = dataBatchTx[0].error as JsonRpcError
            return {error: Error(`${errorData.message} [${errorData.code}]`)}
          }
          let itemSuccess = dataBatchTx[0] as IRpcResponse

          return {data: itemSuccess.result}
        } catch (e) {
          return {error: e}
        }
      },
    })
  }),
})

export const {
  useLazyGetBalanceQuery,
  useLazyGetTransactionCountQuery,
  useLazyGetBatchGasEstimateQuery,
  useLazyGetGasEstimateQuery,
  useLazyGetBatchInfoByAccountQuery,
  useSendBatchSignedTransactionMutation,
  useSendSignedTransactionMutation
} = web3Api