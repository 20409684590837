import {combineReducers, configureStore,} from '@reduxjs/toolkit'
import {web3Api} from "./web3/web3.api";
import {setupListeners} from "@reduxjs/toolkit/query";
import {chainScanApi} from "./common/chainScan.api";
import {chainConfigSlice} from "./common/chainConfig.slice";
import {owlracleApi} from "./owlracle/owlracle.api";

const rootReducer = combineReducers({
  [chainScanApi.reducerPath]: chainScanApi.reducer,
  [web3Api.reducerPath]: web3Api.reducer,
  [chainConfigSlice.reducerPath]: chainConfigSlice.reducer,
  [owlracleApi.reducerPath]: owlracleApi.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(chainScanApi.middleware, web3Api.middleware, owlracleApi.middleware),
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch


setupListeners(store.dispatch)
